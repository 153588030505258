<template>
	<div id="login">
		<div class="background-banner" v-bind:style="{ backgroundImage: 'url(' + banner_background + ')' }">
			<!-- <img class="img-responsive" alt="Logo" :src="banner_background"> -->
		</div>
		<div class="container lateral-wrapper">
			<div class="offset-md-3 col-md-6 offset-sm-2 col-sm-8 col-12 lateral" elevation="2">
				<Logo/>
				<v-form ref="form_usuario">
					<div class="row row-inputs">
						<div class="col-12">
							<label class="primary-text-field-label">Digite seu e-mail*</label>
							<v-text-field
								class="primary-text-field"
								:rules="[v => !!v || 'Campo obrigatório']" 
								v-model="inputs.usuario"
								label="E-mail"
								solo
								hide-details
								@keyup.enter="handleSubmit"
							/>
						</div>
						<div class="col-12">
							<label class="primary-text-field-label">Senha*</label>
							<v-text-field
								class="primary-text-field"
								type="password" 
								:rules="[v => !!v || 'Campo obrigatório']" 
								v-model="inputs.senha"
								label="Senha"
								solo
								hide-details
								@keyup.enter="handleSubmit"
							/>
						</div>
					</div>
					<div class="row">
						<div class="col-sm-6 col-12 lembrar-usuario">
							<v-checkbox 
								v-model="inputs.lembrar_usuario"
								label="Relembre-me"
								hide-details
							/>
						</div>
						<!-- <div class="col-sm-6 col-12 recuperar-senha">
							<router-link to="/admin/recuperar-senha">Esqueceu a senha?</router-link>
						</div> -->
						<div class="col-12 div-wrapper-btn">
							<v-btn class="btn-entrar" @click="handleSubmit" elevation="2" large color="primary" :loading="loading">
								Entrar
							</v-btn>
						</div>
					</div>
				</v-form>
			</div>
		</div>
		<Loader v-if="loading"/>
	</div>
</template>

<script>
	import Logo from "@/components/Logo.vue"
	// import LateralLogin from "@/components/LateralLogin.vue"
	import store from '@/store'
	import Loader from '@/components/Loader.vue'
	import BG from '@/assets/images/BG.png'

	export default {
		name: 'Login',
		components: {
			Loader,
			Logo,
			// LateralLogin
		},
		data() {
			return {
				banner_background: BG,
				loading: false,
				inputs: {
					usuario: localStorage.getItem('usuario') || '',
					senha: localStorage.getItem('senha') || '',
					lembrar_usuario: localStorage.getItem('lembrar_usuario') || '',
				},
				validacao: {
					usuario: false,
					senha: false,
				}
			}
		},
		methods: {
			async handleSubmit(){
				if (this.$refs.form_usuario.validate()){
					
					this.loading = await true
					
					let dados = {
						usuario: this.inputs.usuario,
						senha: this.inputs.senha,
						form: 'formAdmin'
					}
					
					var resp = await store.dispatch('login', dados)
					
					if(resp.data.sucesso){
						this.$router.push('/admin/')
					}else if(resp.data.error == "Sem permissão de acesso"){
						this.$swal({
							title: 'Atenção',
							icon:'warning',
							text: 'Sem permissão de acesso!',
							showCancelButton: false,
							showCloseButton: false,
							showConfirmButton: true,
							confirmButtonText:'Ok'
                    	})
					}
					else{
						this.$swal({
							title: 'Atenção',
							icon:'warning',
							text: 'Login inválido!',
							showCancelButton: false,
							showCloseButton: false,
							showConfirmButton: true,
							confirmButtonText:'Ok'
                    	})
					}
					this.loading = await false
				}
			},
		}
	}
</script>

<style lang="scss">
	#login{
		flex: 1;
		display: flex;
		position: relative;
		align-content: center;
		justify-content: center;
		padding: 75px 0;
		.background-banner{
			width: 100%;
    		height: 50%;
			top: 0;
			background-size: cover;
			position: absolute;
		}
		.lateral{
			position: relative;
			background-color: #fff;
			border: 1px solid #EDEDED;
			box-sizing: border-box;
			box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
			border-radius: 26px;
			padding: 0;
			.logo-empresa{
				transform: translateY(-50px);
				width: 150px;
				height: 150px;
				margin: auto;
				position: relative;
				margin-bottom: -50px;
			}
			.entrar-com{
				padding: 0 45px;
				.text-entrar{
					display: flex;
					padding: 0 12px;
					justify-content: center;
					align-items: center;
					width: 100%;
					p{
						font-family: Poppins;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 24px;
						color: #4F4F4F;
						margin-bottom: 0;
					}
				}
				.btn-entrar-com{
					padding: 10px;
					width: 100%;
					justify-content: center;
					height: auto;
					text-transform: initial;
					font-family: Poppins;
					font-style: normal;
					font-weight: 300;
					font-size: 20px;
					line-height: 30px;
					background: #FFFFFF;
					border: 1px solid #EDEDED;
					box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
					border-radius: 6px;
					&.facebook{
						color: #395185;
					}
					&.google{
						color: #1976D2;
					}
					img{
						margin: 0 15px 0 0;
						width: 35px;
					}
				}
				.text-ou{
					display: flex;
					padding: 0 12px;
					justify-content: center;
					align-items: center;
					width: 100%;
					p{
						font-family: Poppins;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 24px;
						color: #C4C4C4;
						margin: 0 15px;
						position: relative;
					}
					&::before,
					&::after{
						content: '';
						flex: 1;
						border-top: 1px solid #E5E5E5;
					}
				}
				@media (max-width: 767px) {
					padding: 12px;	
				}
			}
			form{
				padding: 0 45px 20px;
				h2{					
					font-style: normal;
					font-weight: 500;
					font-size: 22px;
					line-height: 36px;
					color: $primarycolor;
				}
				.row-inputs{
					padding-bottom: 15px;
				}
				.v-input__slot{
					border-radius: 100px!important;
					padding-left: 15px;
				}
				.lembrar-usuario{
					display: flex;
					align-items: center;
					label{
						display: flex;
						align-items: center;
						font-family: Poppins;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						color: #747474;
						p{
							padding: 0px;
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 17px;
							color: #373737;
						}
						@media (max-width: 620px){
							font-size: 14px;
						}
						input{
							width: auto;
							margin-right: 10px;
						}
					}
					.v-input__slot{
						border-radius: inherit!important;
						padding: inherit!important;
					}
					.v-input--selection-controls{
						margin: 0;
						padding: 0;
					}
					@media (max-width: 390px){
						justify-content: center;
					}
				}
				.recuperar-senha{
					display: flex;
					align-items: center;
					justify-content: flex-end;
					a{
						text-align: right;
						font-family: Poppins;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						/* identical to box height */


						/* COLOR FINER */

						color: #FE8204;
						@media (max-width: 620px){
							font-size: 14px;
						}
					}
					.v-input__slot{
						border-radius: inherit!important;
						padding: inherit!important;
					}
					@media (max-width: 390px){
						justify-content: center;
					}
				}
				.div-wrapper-btn{
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					padding: 12px 45px;
					.btn-entrar{
						border-radius: 100px;
						padding: 18px 20px;
						width: 100%;
						height: 60px;
						span{
							font-family: 'Poppins';
							font-style: normal;
							font-weight: bold;
							font-size: 16px;
							line-height: 24px;
							color: #FFFFFF;
							display: inline-flex;
						}
					}
					@media (max-width: 767px) {
						padding: 12px;	
					}
				}
				.wrapper-novo-usuario{
					padding-top: 20px;
					span{
						font-family: 'Poppins';
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 24px;
						color: #747474;
					}
					.btn-cadastrese{
						background-color: transparent!important;
						box-shadow: inherit!important;
						padding: 0 6px!important;
						span{
							font-family: Poppins;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 24px;
							color: #747474;
							text-transform: initial;
						}
						&:before{
							content: none!important;
						}
						&:hover{
							background-color: transparent!important;
						}
					}
				}
				@media (max-width: 767px) {
					padding: 12px;	
				}
			}
		}
		.imagem{
			flex: 1;
		}
		@media (max-width: 767px) {
			padding: 50px 0;	
		}
	}
	.modal-CriaUsuario{
		padding: 30px;
		.v-card__title{
			font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 42px;
            color: #FE8204;
            padding: 16px 30px;
			@media (max-width: 767px) {
				padding: 12px;	
			}
			label{
				font-size: 16px;
				color: #404040;
				width: 100%;
			}
		}
		.v-card__text{
			.v-input__slot{
				border-radius: 100px!important;
				padding-left: 15px;
			}
			@media (max-width: 767px) {
				padding: 12px !important;	
			}
		}
		.v-card__actions{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			.wrapper-btns{
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-around;
			}
		}
		@media (max-width: 767px) {
			padding: 0px;	
		}
	}
	.wrapper-logoFinerFoods{
		position: fixed;
		bottom: 5%;
		right: 5%;
		@media (max-width: 767px){
			display: none;
		}
	}
</style>